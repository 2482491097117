<template>
    <div>
        <v-app-bar dense :color="params.background">
            <v-btn text small v-for="(item, key) in params.firstMenu" :key="key">{{ item.title }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn text small v-for="(item, key) in params.secondMenu" :key="key + 11">{{ item.title }}</v-btn>
        </v-app-bar>
    </div>
</template>

<script>
import {props} from "./mixin/props";

export default {
    name: "SimpleNavbar",
    mixins: [props],
    props: {
        props: {
            default: () => {
            },
        },
        parse: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        params: {},
    }),
    watch: {
        props() {
            this.setData()
        },
    },
    methods: {
        setData() {
            this.params = this.parse ? this.parseProps(this.props) : this.props
        }
    },
    created() {
        this.setData()
    }
}
</script>

<style scoped>

</style>
