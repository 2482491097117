<template>
    <div>
        <front-layout v-if="isCmsPage()">
            <v-container>
                <v-row class="text-center">
                    <v-col cols="12">
                        <h2>AutoVision CMS Demo</h2>
                    </v-col>
                </v-row>
            </v-container>
        </front-layout>
        <template  v-else>
            <component
                :props="component.props"
                v-bind:is="component.name"
                v-for="(component, id) in components"
                :key="id"/>
        </template>
    </div>
</template>

<script>
import FrontLayout from "@/layouts/futurecarstore/FrontLayout";
import Page from "@/models/Page";

export default {
    name: 'Home',
    components: {FrontLayout},
    data: function () {
        return {
            url: null,
            components: [],
        }
    },
    methods: {
        isCmsPage() {
            return process.env.VUE_APP_CMS_DOMAIN === this.url.hostname
        },
    },
    async created() {
        this.url = new URL(location.href)
        const page = await Page.where('store.name', this.url.hostname).where('type', 'home').first()
        this.components = page.template.components || [];
    },
}
</script>
