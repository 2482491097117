<template>
   <p>
       Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium deleniti dignissimos molestias odio perspiciatis quod.
   </p>
</template>

<script>
export default {
    name: "SimpleText"
}
</script>

<style scoped>

</style>
