<template>
    <div class="section">
        <div class="text-right">
            <v-btn color="blue" text @click="$emit('close')">Close</v-btn>
            <v-btn color="blue darken-1" text @click="$emit('save', copyProps)">Save</v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-tabs
            grow
            v-model="tab"
        >
            <v-tab v-for="item in tabs" :key="item.key">{{ item.title }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item key="o" class="mt-4">
                <div v-for="(item, key) in copyProps.firstMenu" :key="key" class="d-flex align-center">
                    <v-text-field label="text" v-model="item.title"/>
                    <v-text-field label="link" class="ml-2" v-model="item.to"/>
                    <v-btn @click="removeItem(key, 'firstMenu')" icon>
                        <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                </div>
                <div class="text-right">
                    <v-btn @click="addItem('firstMenu')" small color="primary">add link</v-btn>
                </div>
            </v-tab-item>
            <v-tab-item key="1" class="mt-4">
                <div v-for="(item, key) in copyProps.secondMenu" :key="key" class="d-flex align-center">
                    <v-text-field label="text" v-model="item.title"/>
                    <v-text-field label="link" class="ml-2" v-model="item.to"/>
                    <v-btn @click="removeItem(key, 'secondMenu')" icon>
                        <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                </div>
                <div class="text-right">
                    <v-btn @click="addItem('secondMenu')" small color="primary">add link</v-btn>
                </div>
            </v-tab-item>
            <v-tab-item key="3" class="mt-4">
                <v-color-picker
                    dot-size="12"
                    hide-mode-switch
                    mode="hexa"
                    swatches-max-height="250"
                    v-model="copyProps.background"
                ></v-color-picker>
            </v-tab-item>
        </v-tabs-items>
        <div class="mt-10">
            <simple-navbar :props="copyProps" :parse="false"/>
        </div>
    </div>
</template>

<script>
import {props} from "./mixin/props";

export default {
    name: "SimpleNavbarOptions",
    mixins: [props],
    props: {
        props: {
            default: () => {
            },
        },
    },
    data() {
        return {
            copyProps: {},
            tab: null,
            tabs: [
                {title: 'First Menu', key: 'first_menu'},
                {title: 'Second Menu', key: 'second_menu'},
                {title: 'Other', key: 'other'},
            ],
        }
    },
    methods: {
        addItem(type) {
            this.copyProps[type].push({title: '', to: ''})
        },
        removeItem(key, type) {
            this.copyProps[type].splice(key, 1)
        },
    },
    created() {
        this.copyProps = this.parseProps(this.props)
    }
}
</script>

<style scoped>
    .section {
        min-height: 70vh;
    }
</style>
