export const props = {
    data: function () {
        return {
            defaultProps: {
                firstMenu: [],
                secondMenu: [],
                background: '#29B0FFFF',
            },
        }
    },
    methods: {
        parseProps(data) {
            let props = JSON.parse(JSON.stringify(data))
            if (typeof props !== "object" || Array.isArray(props)) {
                props = {...this.defaultProps}
                return props
            }
            if (!(Array.isArray(props.firstMenu))) {
                props.firstMenu = []
            }
            if (!(Array.isArray(props.secondMenu))) {
                props.secondMenu = []
            }
            if (typeof props.background !== 'string') {
                props.background = this.defaultProps.background
            }
            return props
        }
    }
}
